import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import { Col, Container, Row, Card, ListGroup, Spinner, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FcCalculator, FcExpired } from "react-icons/fc";
import ajax from "../Services/fetchService";
import { useUser } from "../UserProvider";
import dayjs from "dayjs";
import "./instructor-dashboard.css";
import InstructorStudentEditModal from "../InstructorStudentEditModal";
import DropdownFilter from "../Dropdown/dropdown";



const InstructorDashboard = () => {
  const user = useUser();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userAssignments, setUserAssignments] = useState(null);
  const [studentEditModal, setStudentEditModal] = useState(<></>);
  const [filter, setFilter] = useState('ALL')
  const dropdownFilterList = ['ALL', 'FOUNDATIONS', 'FULLSTACK', 'PROBATION', 'PAUSED', 'INACTIVE', 'Z_GRAD', 'NON-CONFIGURED'];
  const [deltaFilter, setDeltaFilter] = useState("ALL");
  const deltaDropDownFilterList = ['ALL', '2 OR MORE ASSIGNMENTS BEHIND', '3 OR MORE ASSIGNMENTS BEHIND', '5 OR MORE ASSIGNMENTS BEHIND'];
  const deltaEnum =  {
    '2 OR MORE ASSIGNMENTS BEHIND': 2,
    '3 OR MORE ASSIGNMENTS BEHIND': 3, 
    '5 OR MORE ASSIGNMENTS BEHIND': 5
  }
  const [ totalHoursWatched, setTotalHoursWatched ] = useState(new Map());
  const [ watchHistoryError, setWatchHistoryError ] = useState();
  const [ isSummaryReportCreated, setIsSummaryReportCreated ] = useState(false);

  const handleCloseStudentEdit = () => {
    setStudentEditModal(<></>);
  };

  const handleSaveStudent = (data) => {
    ajax(`/api/users/${data.email}`, "put", user.jwt, data).then(() => {
      setIsSubmitted(true);
      handleCloseStudentEdit();
    });
  };

  const handleFilterChange = (event) => {
    setFilter(event);
  }

  const handleDeltaFilterChange = (event) => {
    setDeltaFilter(event);
  }

  const getVimeoHoursAndSumTotal = (studentEmail) => {
    ajax(`/api/users/video/lessons/watchreport/${studentEmail}`, "GET", user.jwt).then((data) => {
      if(data == null) {
        throw new Error('Error retrieving watch history')
      }
      setTotalHoursWatched(new Map(totalHoursWatched.set(studentEmail, data.totalHoursWatched)));
    }).catch((error) => {
      setWatchHistoryError(error);
    })
  }

  const handleCheckIfSummaryReportIsCreated = (isSummaryReportCreated) => {
    setIsSummaryReportCreated(isSummaryReportCreated);
  }

  useEffect(() => {
    if(deltaFilter != "ALL" && deltaFilter != null) {
      ajax("/api/assignments/all?status=" + filter + "&delta=" + deltaEnum[deltaFilter], "get", user.jwt).then((data) => {
        setUserAssignments(data);
      });
    } else if (filter == "NON-CONFIGURED") {
      ajax("/api/users/non-configured", "get", user.jwt).then((data) => {
        setUserAssignments(data);
      });
    } else {
      ajax("/api/assignments/all?status=" + filter, "get", user.jwt).then((data) => {
        setUserAssignments(data);
      });
    }
  }, [filter, deltaFilter, isSubmitted, isSummaryReportCreated]);

  const getColor = (delta) => {
    if (delta <= 0) {
      return "#b2e0b2"; // green
    } else if (delta === 1) {
      return "yellow"; // yellow
    } else if (delta === 2) {
      return "orange"; // orange
    } else if (delta === 3) {
      return "#f65555"; // red
    } else if (delta >= 4) {
      return "#c70000";
    }
  };

  const getReportCheckedInStyle = (assignmentsExpected, isSummaryReportCreated) => {
    if (assignmentsExpected > 0 && isSummaryReportCreated == false) {
      return "bold"
    }
  };

  const tooltip = (tooltipText) => (
    <Tooltip id="tooltip">
      <strong>{tooltipText}</strong>
    </Tooltip>
  );
  return (
    <>
    <NavBar />
      <Container>       
        {studentEditModal}
        <Row className="mt-4">
          <Col>
            <h1>Instructor Dashboard</h1>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Card
          bg="primary"
            key={'Dark'}
            style={{ width: '32rem' }}
            className="mb-2"
            border="primary">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <i><b>Filter By Status: </b></i><DropdownFilter 
                  label={filter}  
                  options={dropdownFilterList}
                  onChange={handleFilterChange}
                ></DropdownFilter>
              </ListGroup.Item>
              <ListGroup.Item>              
                <span><i><b>Filter by Assignments Expected Delta: </b></i><DropdownFilter
                  label={deltaFilter}
                  options={deltaDropDownFilterList}
                  onChange={handleDeltaFilterChange}
                >
                </DropdownFilter></span>
              </ListGroup.Item>
            </ListGroup>
          </Card>     
        </Row>
        <hr/>
        <Row>
          
          <h5>Filtering by status {filter} and with assignments expected delta of {deltaFilter}</h5>
        </Row>
        <Row className="mt-4">                
          {
            userAssignments ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Cohort</th>
                      <th>Name</th>
                      <th title="Watch Index"> 
                        <OverlayTrigger placement="top" overlay={tooltip("Watch Index")}>
                          <strong>WI</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Assignments Submitted">
                        <OverlayTrigger placement="top" overlay={tooltip("Assignments Submitted")}>
                          <strong>AS</strong>
                        </OverlayTrigger></th>
                      <th title="Assignments Expected">
                        <OverlayTrigger placement="top" overlay={tooltip("Assignments Expected")}>
                          <strong>AE</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Last Submitted Date">
                        <OverlayTrigger placement="top" overlay={tooltip("Last Submitted Date")}>
                          <strong>Last</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Weeks Progressed">
                        <OverlayTrigger placement="top" overlay={tooltip("Weeks Progressed")}>
                          <strong>Wk</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Minimum Checkin Required">
                        <OverlayTrigger placement="top" overlay={tooltip("Minimum Checkin Required")}>
                          <strong>Min</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Preferred Checkin Required">
                        <OverlayTrigger placement="top" overlay={tooltip("Preferred Checkins Required")}>
                          <strong>Prf</strong>
                        </OverlayTrigger>
                      </th>
                      <th title="Vimeo hours Watched in the Last Month">
                        <OverlayTrigger placement="top" overlay={tooltip("Vimeo hours Watched in the Last Month")}>
                          <strong>Vimeo</strong>
                        </OverlayTrigger>
                      </th>
                      <th>Status</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        Object.entries(userAssignments).map((entry, idx) => {
                          const [keyData, value] = entry;
                          let key = JSON.parse(keyData);
                          return  <tr key={`${key.email}-active`} onClick={(event) => {                           
                            setIsSubmitted(false)
                            setStudentEditModal(
                              <InstructorStudentEditModal
                                emitClose={handleCloseStudentEdit}
                                studentEmail={key.email}
                                emitSave={handleSaveStudent}
                                studentInfo={key}
                                emitIsSaveSummaryReport={handleCheckIfSummaryReportIsCreated}
                              />
                            );
                          }}>
                          <td
                            style={{
                              backgroundColor: getColor(key.assignmentsExpected),
                            }}
                          ></td>
                          <td>
                            {dayjs(key.startDate, "YYYY-M-D").format("MMM YYYY")}
                          </td>
                          <td>{(key.firstName != null && key.lastName != null) ? (key.firstName + " " +  key.lastName) : key.name}</td>
                          <td className="text-center">{key.watchIndex}</td>
                          <td className="text-center">{key.assignmentsSubmitted}</td>
                          <td className="text-center"><span style={{
                            fontWeight: getReportCheckedInStyle(key.assignmentsExpected, key.isSummaryReportCreated)
                          }}>{key.assignmentsExpected} {key.assignmentsExpected > 0 && key.isSummaryReportCreated == false ? <FcExpired size={"30px"}/> : ""}</span></td>
                          <td className="text-center">{key.lastSubmittedAssignmentDate != null ? dayjs(key.lastSubmittedAssignmentDate, "YYYY-M-D").format("MMM D") : "NONE"}</td>
                          <td className="text-center">{key.weeksInBootcamp}</td>
                          <td className="text-center">{key.weeksInBootcamp * 2}</td>
                          <td className="text-center">{key.weeksInBootcamp * 5}</td>
                          <td><Button className= "vimeoButton" size="sm" variant="secondary" onClick={(event) => {
                            event.stopPropagation();
                            getVimeoHoursAndSumTotal(key.email)                           
                          }}><FcCalculator size={"30px"}/>{totalHoursWatched && totalHoursWatched.has(key.email) ? " " +  totalHoursWatched.get(key.email) : ""}</Button></td>
                          {
                            key.status != null ? <td>{key.status.status}</td> : <td></td>
                          }
                          <td>{key.email}</td>
                        </tr>
                      })
                    }
                  </tbody>                    
                </table>
              </> 
            )            
            : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </Row>
      </Container>
    </>
  );
};

export default InstructorDashboard;
