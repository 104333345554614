import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import logo from "../Images/coders-campus-logo.png";
import { useUser } from "../UserProvider";
import ajax from "../Services/fetchService";
import jwt_decode from "jwt-decode";

function NavBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useUser();
  const [authorities, setAuthorities] = useState(null);

  useEffect(() => {
    if (user && user.jwt) {
      const decodedJwt = jwt_decode(user.jwt);
      setAuthorities(decodedJwt.authorities);
    }
  }, [user, user.jwt]);

  return (
    <div className="NavBar nav d-flex justify-content-around justify-content-lg-between">
      <div className="ms-md-5">
        <Link to="/">
          <Image src={logo} alt="logo" className="logo" />
        </Link>
      </div>
      <div>
        {user && user.jwt ? (
          <span
            className="link"
            onClick={() => {
              // TODO: have this delete cookie on server side
              ajax("/api/auth/logout", "get").then((response) => {
                if (response != null) {
                  user.setJwt(null);
                  navigate("/");
                }
              });
            }}
          >
            Logout
          </span>
        ) : pathname !== "/login" ? (
          <Button
            variant="primary"
            className="me-5"
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </Button>
        ) : (
          <></>
        )}

        {authorities &&
        authorities.filter((auth) => auth === "ROLE_INSTRUCTOR").length > 0 ? (
          <Link
            className="ms-5 ms-md-5 me-md-5 link"
            to="/instructors/dashboard"
          >
            Instructors
          </Link>
        ) : (
          <></>
        )}
        {/* FOR TESTING IN DEV ONLY */}
        {/* <Link
          className="ms-5 ms-md-5 me-md-5 link"
          to={"/chat"}>
          Instructor Working Group
        </Link> */}
        {/* {authorities &&
        authorities.filter((auth) => auth === "ROLE_STUDENT").length > 0 ? (
        <Link
          className="ms-5 ms-md-5 me-md-5 link"
          to={"/chat"}>
          Instructor Working Group
        </Link>
      ) : (
        <></>
      )} */}
        {user && user.jwt ? (
          <Button
            className="ms-5 ms-md-5 me-md-5"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default NavBar;
