import React, {useState, useEffect} from "react";
import NavBar from "../NavBar";
import SideBar from "../SideBar";
import Chat from "./Student/Chat";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ajax from "../Services/fetchService";
import { useUser } from "../UserProvider";
import jwt_decode from "jwt-decode";

import './studentChat.css';
import ChatRoom from "./ChatRoom";


const ChatApp = () => {

    const [messages, setMessages] = useState([]);
    const user = useUser();
    const decodedJwt = jwt_decode(user.jwt);
    const [student, setStudent] = useState({});

    useEffect(() => {
        ajax(`/api/users/${decodedJwt.sub}`, "get", user.jwt).then((data) => {
          setStudent(data);
        })
        
    }, []);

    return (
        <>
            <div>
                <NavBar/>
                <Container fluid>
                    <Row>
                        {/* <div id="sidebar-wrapper">
                            <SideBar roleList={student.authorities}/>
                        </div>
                        <div id="page-content-wrapper">
                            <Chat/>    
                        </div> */}
                        {console.log(student.name)}
                        <ChatRoom loggedInUser = {student} />
                    </Row>
                </Container>
            </div>
            
        </>
    )
    

}

export default ChatApp;