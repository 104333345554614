import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownFilter = ({label, options, onChange}) => {
    return (
        <Dropdown onSelect={onChange}>
            <Dropdown.Toggle>
                {label}  
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((option, idx) => 
                    <Dropdown.Item eventKey={option} key={idx} >{option}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )

    
};

export default DropdownFilter;