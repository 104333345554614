import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";


const FormReadOnlyTextField = ({
    as,
    md,
    controlId,
    label,
    name,
    type,
    onChange,
    value
  }) => {
    return (
      <Field
        name={name}
        render={({ field, form }) => {
          return (
            <Form.Group as={as} md={md} controlId={controlId}>
              <Form.Label>{label}</Form.Label>
              <InputGroup>
                <Form.Control
                  {...field}
                  type={type}
                  onChange={onChange}
                  placeholder={value}
                  readOnly
                  plaintext
                />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    );
  };
  
  export default FormReadOnlyTextField;