import { Form, Button, Modal, Col, Row, Accordion, Toast, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useUser } from "../UserProvider";
import ajax from "../Services/fetchService";
import { Formik } from "formik";
import * as yup from "yup";
import jwt_decode from "jwt-decode";
import FormTextField from "../FormUtil/form-field";
import FormSelectField from "../FormUtil/form-select-field";
import FormRangeSlider from "../FormUtil/form-field-range-slider";
import InstructorStudentFileReportModal from "../InstructorStudentFileReportModal";

import "./instructor-student-edit-modal.css";
import InstructorSummaryReportModal from "../InstructorSummaryReportModal";

const InstructorStudentEditModal = (props) => {
  const { emitClose, emitSave, studentEmail, studentInfo, emitIsSaveSummaryReport } = props;
  const user = useUser();
  const decodedJwt = jwt_decode(user.jwt);
  const [ staffName, setStaffName ] = useState("");
  const [student, setStudent] = useState(null);
  const [studentFileReportModal, setStudentFileReportModal] = useState(<></>);
  const [studentSummaryReportModal, setStudentSummaryReportModal] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileReportClicked, setIsFileReportClicked] = useState(false);
  const [isStudentSummaryClicked, setIsStudentSummaryClicked] = useState(false);
  const [watchHistory, setWatchHistory] = useState();
  const [totalHoursWatched, setTotalHoursWatched ] = useState();
  const [watchHistoryError, setWatchHistoryError] = useState();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    cohortStartDate: null,
    status: null, 
    bootcampDurationInWeeks: null,
    watchIndex: "3",
  });
  const rangeValues = {
    1: "Student has a complete independant learning style and will complete the bootcamp of their own accord",
    2: "Student has been contacted and is willing to complete necessary assignments to catch up",
    3: "Student has shown warning signs that they may fall behind. Watch closely",
    4: "Student has been contacted but there is a slim chance that they will pass on their own accord",
    5: "Student has been contacted and is completely unreachable and shows no semblance of completing the bootcamp",
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("first name is required").nonNullable(),
    lastName: yup.string().required("last name is required").nonNullable(),
    status: yup.string().required("please choose a status for this student"),
    cohortStartDate: yup.date().required("cohort start date is required"),
    bootcampDurationInWeeks: yup
      .number()
      .oneOf([24, 36])
      .required("please input whether bootcamp is either 24 or 36 weeks")
      .nonNullable(),
  });
  const statusEnum = {
    FOUNDATIONS: {
      id: 1,
      status: "FOUNDATIONS",
    },
    FULLSTACK: {
      id: 2,
      status: "FULLSTACK",
    },
    PROBATION: {
      id: 3,
      status: "PROBATION",
    },
    PAUSED: {
      id: 4,
      status: "PAUSED",
    },
    INACTIVE: {
      id: 5,
      status: "INACTIVE",
    },
    Z_GRAD: {
      id: 6,
      status: "Z_GRAD",
    },
    "NON-CONFIGURED": {
      id: 7,
      status: "NON-CONFIGURED",
    },
  };

  useEffect(() => {
    if (student) {
      setInitialValues(student);
      setIsLoading(true);
    }
  }, [student]);

  const handleCloseStudentFileReportModal = () => {
    setIsFileReportClicked(false);
    setStudentFileReportModal(<></>);
  };

  const handleCloseSummaryReportModal = () => {
    setIsStudentSummaryClicked(false);
    setStudentSummaryReportModal(<></>);
  }

  const handleSaveRecord = (email, data) => {
    console.log(data)
    ajax(`/api/records/${email}`, "POST", user.jwt, data).then((res) => {
      console.log(res);
      handleCloseStudentFileReportModal();
    })
  };

  const handleSaveStudentSummary = (email, data) => {
    ajax(`/api/records/summary/save/${email}`, "POST", user.jwt, data).then((res) => {
      emitIsSaveSummaryReport(true);
      handleCloseSummaryReportModal();
    })
  }

  useEffect(() => {
    emitIsSaveSummaryReport(false)
    ajax(`/api/users/${studentEmail}`, "get", user.jwt).then((data) => {
      if (!data) {
        let yesNo = window.confirm(
          "User doesn't exist in the Assignment Submission app, do you want to create the user?"
        );
        if (yesNo) {
          ajax(`/api/users/${studentEmail}`, "put", user.jwt, {
            email: studentEmail,
          }).then((data) => {
            emitClose();
          });
        } else {
          emitClose();
        }
      }
      data.email = data.username;
      setStudent(data);
    });

    ajax(`/api/users/${decodedJwt.sub}`, "get", user.jwt).then((data) => {
      setStaffName(data.name);
  })
  }, []);

  useEffect(() => {
    setWatchHistoryError(null);
    ajax(`/api/users/video/lessons/watchreport/${studentEmail}`, "GET", user.jwt).then((data) => {
      if(data == null) {
        throw new Error('Error retrieving watch history')
      }
      setWatchHistory(data.watchReportList);
      setTotalHoursWatched(data.totalHoursWatched);
    }).catch((error) => {
      console.log(error)
      setWatchHistoryError(error);
    })
  }, [])

  const handleSaveStudent = (values) => {
    const studentCopy = { ...student,
      firstName: values.firstName,
      lastName: values.lastName,
      cohortStartDate: values.cohortStartDate,
      status: statusEnum[values.status],
      bootcampDurationInWeeks: values.bootcampDurationInWeeks,
      watchIndex: values.watchIndex,
    };
    emitSave(studentCopy);
  };

  return (
    <>
    {isFileReportClicked ? studentFileReportModal : <></>}
    {isStudentSummaryClicked ? studentSummaryReportModal : <></>}
      <Modal show={true} onHide={emitClose}>
        {isLoading ? (
          <>
            <Modal.Header closeButton>
              {/* TODO: student name is temporary here until existing data can be reentered as first and last name
              change to concantenated first and last name after existing data has been reformatted to first and last name */}
              <Modal.Title>Edit Student Info for {student.firstName != null && student.lastName != null ? student.firstName + " " + student.lastName : student.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={handleSaveStudent}
                initialValues={{
                  firstName: initialValues.firstName,
                  lastName: initialValues.lastName,
                  cohortStartDate: initialValues.cohortStartDate,
                  status:
                    initialValues.status != null
                      ? initialValues.status.status
                      : null,
                  bootcampDurationInWeeks:
                    initialValues.bootcampDurationInWeeks,
                  watchIndex: initialValues.watchIndex,
                }}
              >
                {({ handleSubmit, handleChange, isValid, isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Col>
                      <FormTextField
                        as={Col}
                        sm="4"
                        controlId="firstName"
                        label="First Name"
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                      />

                      <FormTextField
                        as={Col}
                        sm="4"
                        controlId="lastName"
                        label="Last Name"
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                      />

                      <FormTextField
                        as={Col}
                        sm="4"
                        controlId="cohortStartDate"
                        label="Start Date"
                        type="date"
                        name="cohortStartDate"
                        onChange={handleChange}
                      />

                      <FormSelectField
                        as={Col}
                        sm="4"
                        controlId="status"
                        label="Bootcamp Status"
                        type="text"
                        name="status"
                        onChange={handleChange}
                      >
                        <option value={""}>
                          Select the current status of the student
                        </option>
                        <option>FOUNDATIONS</option>
                        <option>FULLSTACK</option>
                        <option>PROBATION</option>
                        <option>PAUSED</option>
                        <option>INACTIVE</option>
                        <option>Z_GRAD</option>
                        <option>NON-CONFIGURED</option>
                      </FormSelectField>

                      <FormSelectField
                        as={Col}
                        sm="4"
                        controlId="bootcampDurationInWeeks"
                        label="Bootcamp Duration (Weeks)"
                        type="text"
                        name="bootcampDurationInWeeks"
                        onChange={handleChange}
                      >
                        <option value={""}>
                          Select the Duration of the Bootcamp
                        </option>
                        <option>24</option>
                        <option>36</option>
                      </FormSelectField>

                      <FormRangeSlider
                        controlId="watchIndex"
                        label="Watch Index"
                        name="watchIndex"
                        onChange={handleChange}
                        rangeValues={rangeValues}
                        defaultInitialValue={1}
                        max={5}
                        min={1}
                        step={1}
                      />
                      <hr/>
                      {
                        watchHistoryError == null ? 
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Vimeo Watch History</Accordion.Header>
                            <Accordion.Body>
                              {
                                
                                  watchHistory && watchHistory.length != 0 ?
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Week Start</th>
                                        <th>Week End</th>
                                        <th>Hours Watched</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        Object.values(watchHistory).map((history, index) => {
                                          return <tr key={index}>
                                            <td>{history.weekStart}</td>
                                            <td>{history.weekEnd}</td>
                                            <td>{history.hoursWatched}</td>
                                          </tr>
                                        })
                                      }
                                    </tbody>
                                  </table>
                                  :
                                  <h5>No videos watched within 4 weeks of today</h5>
                              }
                            </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>                     
                      : <Toast  
                          className="mt-3"
                          bg='danger && text-white'>
                            <Toast.Body>Error loading Vimeo Watch History</Toast.Body>
                        </Toast>
                      }
                          
                    </Col>
                    <Col className="mt-5">
                    <ButtonToolbar aria-label="Report Toolbar" className="justify-content-between">
                      <ButtonGroup vertical className="me-2" aria-label="Report Options">
                        <Button
                          variant="info"
                          as="input"
                          size="md"
                          type="button"
                          value="View Student File Report"
                          onClick={() => {
                            setIsFileReportClicked(true);
                            setStudentFileReportModal(
                            <InstructorStudentFileReportModal
                              student={student}
                              staffName={staffName}
                              emitClose={handleCloseStudentFileReportModal}
                              emitSaveRecord={handleSaveRecord}       
                            />
                          )}}
                        />
                        <Button
                          variant="warning"
                          as="input"
                          size="md"
                          type="button"
                          value="Generate Summary Report"
                          onClick={() => {
                            setIsStudentSummaryClicked(true);
                            setStudentSummaryReportModal(<InstructorSummaryReportModal
                              student={student}
                              studentInfo={studentInfo}
                              staffName={staffName}
                              totalHoursWatched={totalHoursWatched}
                              emitClose={handleCloseSummaryReportModal}
                              emitSaveSummaryReport={handleSaveStudentSummary}
                            />)
                          }}
                        />     
                                  
                      </ButtonGroup>
                    </ButtonToolbar>
                                                  
                    </Col>
                    <Row>
                      <Col className="mt-5">
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="success && text-white"
                          as="input"
                          size="md"
                          type="submit"
                          value="Submit"
                        />

                        <Button
                          className="mx-2"
                          variant="secondary"
                          onClick={emitClose}
                          value="Close"
                          as="input"
                          size="md"
                          type="button"
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </>
        ) : (
          <div className="p-5">Loading...</div>
        )}
      </Modal>
    </>
  );
};

export default InstructorStudentEditModal;
