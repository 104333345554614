import React, { useEffect, useState } from "react";
import { Form, InputGroup, Toast } from "react-bootstrap";
import { Field } from "formik";

const FormRangeSlider = ({
    controlId,
    label,
    name,
    onChange,
    rangeValues,
    defaultInitialValue,
    max,
    min,
    step
  }) => {
    const initialValue = defaultInitialValue != null ? defaultInitialValue.toString(): null;
    const [range, setRange ] = useState(initialValue);
    
    useEffect(() => {
        let colour = handleToastColour(range);
        setToastColour(colour);
    }, [range])

    const handleToastColour = (rangeValue) => {
        switch(rangeValue) {
            case "1":
                return 'success && text-white'
            case "2":
                return 'dark && text-white'
            case "3":
                return 'info && text-white'
            case "4":
                return 'warning && text-white'
            case "5":
                return 'danger && text-white'
        }
    }
    const [ toastColour, setToastColour] = useState(handleToastColour(initialValue));
    return (
      <Field
        name={name}
        render={({ field, form }) => {{
            return (
                <Form.Group controlId={controlId}>
                    <Form.Label>{label}</Form.Label>                    
                    <InputGroup>
                    <Form.Range
                        value={range}
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        onChange={(event) => {
                            onChange(event)
                            return setRange(event.target.value)
                        }}
                    />
                    <Toast
                        className="mt-3"
                        bg={toastColour}
                        >
                        <Toast.Header closeButton={false}>
                            <strong className="me-auto">Watch Index: {range}</strong>
                        </Toast.Header>
                        <Toast.Body>{rangeValues[range]}</Toast.Body>
                    </Toast>
                    </InputGroup>
                </Form.Group>
            )
        }
    }}
      />
    );
  };

export default FormRangeSlider;