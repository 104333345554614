const apiUrl = process.env.REACT_APP_API_URL;

function ajax(url, requestMethod, jwt, requestBody) {
  const fetchData = {
    headers: {
      "Content-Type": "application/json",
    },
    method: requestMethod,
    credentials: "include"
  };

  if (jwt) {
    fetchData.headers.Authorization = `Bearer ${jwt}`;
  }

  if (requestBody) {
    fetchData.body = JSON.stringify(requestBody);
  }
  console.log("apiUrl is: " + apiUrl);
  console.log("url is " + url);
  if (url.substring(0, 1) === '/') {
    
    url = apiUrl + url.substring(1);
    console.log("url is now: " + url);
  } else {
    url = apiUrl + url;
    console.log("url is now: " + url);
  }
  

  return fetch(url, fetchData).then((response) => {
    if (response.status === 200) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.text();
      }
    } else {
      return null;
    }
  });
}

export default ajax;
