import React, {useState, useEffect} from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useUser } from "../UserProvider";
import jwt_decode from "jwt-decode";
import ajax from "../Services/fetchService";
import FormReadOnlyTextField from "../FormUtil/form-field-readonly";
import FormTextAreaTextField from "../FormUtil/form-field-textarea";

import "./instructor-summary-report-modal.css"

const InstructorSummaryReportModal = (props) => {
    const { student, emitClose, emitSaveSummaryReport, studentInfo, staffName } = props;
    const user = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const decodedJwt = jwt_decode(user.jwt);
    const [initialValues, setInitialValues] = useState({
        staffName: staffName,
        student: student,
        notes: "",
    });
    const schema = yup.object().shape({
        summaryReport: yup.string().required("Notes cannot be left blank")
    });

    const [userData, setUserData] = useState({});
    const [ studentSummaryJSON, setStudentSummaryJSON ] = useState("Loading...");

    useEffect(() => {
        setIsLoading(true);
        ajax(`/api/records/summary/${studentInfo.email}`, "POST", user.jwt, studentInfo).then((data) => {
            if(data == null) {
              throw new Error('Error retrieving summary record')
            }
            console.log(data)
            setStudentSummaryJSON(data);
          }).catch((error) => {
            console.log(error)
          })
      }, [])
    
    const handleSaveRecord = (values) => { 
        const recordToSave = {
            "jsonData": JSON.stringify(studentSummaryJSON, null, 2),
            "student": student,
            "summaryReport": values.summaryReport
        }
        emitSaveSummaryReport(student.email, recordToSave);
    };

    const PrettyPrintJSON = () => {       
        return (
        <pre className="code-block"><code>{JSON.stringify(studentSummaryJSON, null, 2)}</code></pre>
    )}

    return (
        <>
          <Modal show={true} onHide={emitClose}>
            {isLoading ? (
              <>
                <Modal.Header closeButton>
                  {/* TODO: student name is temporary here until existing data can be reentered as first and last name
                  change to concantenated first and last name after existing data has been reformatted to first and last name */}
                  <Modal.Title>Student Summary Report for {student.firstName != null && student.lastName != null ? student.firstName + " " + student.lastName : student.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Formik
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={handleSaveRecord}
                    initialValues={{
                      staffName: initialValues.staffName,
                      summaryReport: initialValues.notes,
                    }}
                  >
                    {({ handleSubmit, handleChange, isValid, isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Col>
                            <FormReadOnlyTextField
                                as={Row}
                                sm="4"
                                controlId="staffName"
                                label="Staff Generating the Report"
                                type="text"
                                name="staffName"
                                value={staffName != "" ? staffName : ""}
                                onChange={handleChange}
                            />

                            <PrettyPrintJSON/>
    
                            <FormTextAreaTextField
                                as={Col}
                                sm="4"
                                controlId="summaryReport"
                                label="Summary Report"
                                type="text"
                                name="summaryReport"
                                rows={5}
                                onChange={handleChange}
                                readOnly={false}
                                value={"Please enter your summary pertaining to this student"}
                            />
                              
                        </Col>
                        <Row>
                          <Col className="mt-5">
                            <Button
                              disabled={!isValid || isSubmitting}
                              variant="success && text-white"
                              as="input"
                              size="md"
                              type="submit"
                              value="Submit"
                            />
    
                            <Button
                              className="mx-2"
                              variant="secondary"
                              onClick={emitClose}
                              value="Close"
                              as="input"
                              size="md"
                              type="button"
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </>
            ) : (
              <div className="p-5">Loading...</div>
            )}
          </Modal>
        </>
      );

    

}

export default InstructorSummaryReportModal;