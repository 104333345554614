import React, { useEffect, useState } from 'react'
import {over} from 'stompjs';
import SockJS from 'sockjs-client';
import './chatRoom.css';
import ajax from '../Services/fetchService';
import { useUser } from '../UserProvider';
import jwt_decode from "jwt-decode";

import { Button, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import FormTextField from '../FormUtil/form-field';


const ChatRoom = ({ loggedInUser }) => {
    const [ topics, setTopics ] = useState(new Map);
    const [ isNewTopic, setIsNewTopic ] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [privateChats, setPrivateChats] = useState(new Map());  
    const [messages, setMessages] = useState(new Map()); 
    const [tab,setTab] =useState("INSTRUCTOR CHAT");
    const user = useUser();
    const [isConnected, setIsConnected] = useState(false);
    const decodedJwt = jwt_decode(user.jwt);
    const [stompClient, setStompClient] = useState(null);
    const [userData, setUserData] = useState({
        topicName: '',
        topicDescription: '',
        isActiveMessage: false,
        sender: loggedInUser.name,
        senderEmail: decodedJwt.sub,
        recipient: '',
        connected: false,
        message: '',
        status: ''
      });
    useEffect(() => {
      setUserData({ ...userData, "sender" : loggedInUser.name })
    }, [loggedInUser]);

    useEffect(() => {
        ajax("api/messages/" + decodedJwt.sub, "GET", user.jwt).then((data) => {
            let topicsFromDB = new Map;
            let messagesFromDB = new Map;
            data.forEach(message => {
                let topic = {
                    "topicName": message.topicName,
                    "topicDescription": message.topicDescription
                }
                if (!topicsFromDB.has(topic.topicName)) {
                    topicsFromDB.set(topic.topicName, topic);
                }
                message.message = Array.of(message.message);
                if (messagesFromDB.has(message.topicName)) {
                    (messagesFromDB.get(message.topicName).message).push(message.message[0]);
                } else {
                    messagesFromDB.set(message.topicName, message);
                }
                

            })
            setTopics(topicsFromDB);
            setMessages(messagesFromDB)
        });
    }, []);


    useEffect(() => {
        let sock = new SockJS(process.env.REACT_APP_API_URL + 'ws');
        let client = over(sock);
        
        client.connect({}, (() => {
            setStompClient(client);
        }));        
    }, [])

    useEffect(() => {
        if (stompClient != null) {
            setIsConnected(true);        
            setUserData({...userData,"connected": true});
            if (decodedJwt.authorities.includes("ROLE_STUDENT")) {
                stompClient.subscribe('/instructorGroup/messages')
                setUserData({...userData, "recipient": "INSTRUCTOR_GROUP"})
            } 
        }
        
        // if (decodedJwt.authorities.includes("ROLE_INSTRUCTOR")) {
        //     stompClient.subscribe('/user/' + userData.sender + '/private', onPrivateMessage);
        // } 
        // var chatMessage = {
        //     sender: userData.sender,
        //     senderEmail: userData.senderEmail,
        //     status:"JOIN"
        //   };
        //   if (stompClient != null && stompClient.connected == true) {
        //     stompClient.send("/app/instructor-group-message", { status: "JOIN"}, JSON.stringify(chatMessage));
        //   }
          
    }, [stompClient])

    useEffect(() => {
        if (isSendingMessage == true && userData.message != "" && userData.status != null && userData.status == "MESSAGE") {
            sendMessage();
            setIsSendingMessage(false);
        }
    }, [userData])

    const onError = (err) => {
        console.log(err);        
    }

    const handleTopicAdd = (values) => {
        let newTopicList = topics;
        if (!newTopicList.has(values.topicName)) {
            newTopicList.set(values.topicName, values);
        }
        setTopics(newTopicList);
        let currentMessages = messages;
        var chatMessage = {
            sender: userData.sender,
            senderEmail: userData.senderEmail,
            message: currentMessages && currentMessages.has(values.topicName) ? currentMessages.get(values.topicName).message : [""],
            topicName: values.topicName,
            topicDescription: values.topicDescription, 
            status:""
        };      
        currentMessages.set(values.topicName, chatMessage);
        setIsNewTopic(false)
        setMessages(currentMessages)
        setUserData({...userData, "topicName": values.topicName, "topicDescription" : values.topicDescription})
    }

    const handleMessage =(event)=>{
        const {value}=event.target;
        setUserData({...userData,"message": value});
    }

    const sendMessage=()=>{
            if (stompClient) {
              var chatMessage = {
                sender: userData.sender,
                senderEmail: userData.senderEmail,
                recipient: userData.recipient,
                message: userData.message,
                topicName: userData.topicName,
                topicDescription: userData.topicDescription,
                isActiveMessage: userData.isActiveMessage,
                status: userData.status,
              };
              stompClient.send('/app/instructor-group-message', {}, JSON.stringify(chatMessage));

              let allCurrentMessages = messages;
              let currentMessage = messages.get(userData.topicName);
              currentMessage.message.push(userData.message);
              allCurrentMessages.set(userData.topicName, currentMessage);
              setMessages(allCurrentMessages)
              setUserData({...userData, "message": ""});
            }
    }

    return (
        <div className="container">
            <div className="chat-box">
                <div className="member-list">
                    <ul>
                        {
                            Array.from(topics).map((topic, index)=>{
                                return <li onClick={()=>{                                    
                                    setTab(topic[1].topicName)
                                    setUserData({...userData, "topicName": topic[1].topicName, "topicDescription": topic[1].topicDescription})
                                }} className={`member ${tab===topic[1].topicName && "active"}`} key={index}>{topic[1].topicName}</li> 
                            })
                        }
                        {
                            isNewTopic ? (
                                <Formik
                                    enableReinitialize={true}
                                    onSubmit={handleTopicAdd}
                                    initialValues={{
                                        topicName: "",
                                        topicDescription: ""
                                    }}
                                >
                                    {({ handleSubmit, handleChange, isValid, isSubmitting, values }) => (
                                        
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>What Would you Like to Discuss?</Card.Title>
                                                    <FormTextField
                                                        as={Col}
                                                        sm="4"
                                                        controlId="topicName"
                                                        label="Discussion Topic"
                                                        type="text"
                                                        name="topicName"
                                                        onChange={handleChange}
                                                    />
                                                    <FormTextField
                                                        as={Col}
                                                        sm="4"
                                                        controlId="topicDescription"
                                                        label="Short Description about Your Topic"
                                                        type="text"
                                                        name="topicDescription"
                                                        onChange={handleChange}
                                                    />
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button type="submit" variant='outline-success' onClick={() => {
                                                        setTab(values.topicName); 
                                                        
                                                    }} className={`member ${tab===values.topicName && "active"}`}>Start Chat</Button>
                                                    <Button variant='outline-danger' onClick={() => {
                                                        setIsNewTopic(false)
                                                        setUserData({...userData, "isActiveMessage": false})
                                                    }}>Close New Topic</Button>
                                                </Card.Footer>
                                            </Card>
                                            </Col>
                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <Button type="button" onClick={() => setIsNewTopic(true)}>
                                    Ask Your Question!
                                </Button>
                            )
                        }
                        
    
                    </ul>
                    
                </div>
                {<div className="chat-content">
                    <ul className="chat-messages">
                        {topics != null ? [...Array.from(messages).filter((message) => message[1].topicName == tab)].map((chat,index)=>(
                            
                            <Card key={index}>
                                <Card.Header as="h5" className='text-center'>Topic Description: <i>{chat[1].topicDescription}</i></Card.Header>
                                <Card.Body>
                                    {
                                        
                                        chat[1].message.map((message, messageIndex) => {                                            
                                            return <li className={`message ${chat[1].sender === userData.sender && "self"}`} key={messageIndex}>
                                                {chat[1].sender !== userData.sender && <div className="avatar">{chat[1].sender}</div>}
                                                <div className="message-data" key={messageIndex}>
                                                    {
                                                        message != "" ? 
                                                            message : 
                                                            <i>Type message to start chat</i>
                                                    }
                                                </div>
                                                {chat[1].sender === userData.sender && <div className="avatar self">{chat[1].sender}</div>}
                                            </li>
                                        })
                                    }
                                </Card.Body>
                            </Card>
                            
                        )) : ""}
                    </ul>

                    <div className="send-message">
                        <input type="text" className="input-message" placeholder="enter the message" value={userData.message} onChange={handleMessage} /> 
                        <button type="button" className="send-button" onClick={() => {
                            setIsSendingMessage(true)
                            setUserData({...userData, "isActiveMessage": true, "status": "MESSAGE"})                                                   
                        }}>send</button>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default ChatRoom