import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";

const FormTextAreaTextField = ({
    as,
    md,
    controlId,
    label,
    name,
    type,
    onChange,
    value,
    rows,
    readOnly
  }) => {
    return (
      <Field
        name={name}
        render={({ field, form }) => {
            const isValid = !form.errors[field.name];
            const isInvalid = form.touched[field.name] && !isValid;
          return (
            <Form.Group as={as} md={md} controlId={controlId}>
              <Form.Label>{label}</Form.Label>
              <InputGroup>
                <Form.Control
                  {...field}
                  as="textarea"
                  isValid={form.touched[field.name] && isValid}
                  isInvalid={isInvalid}
                  feedback={form.errors[field.name]}
                  name={name}
                  rows={rows}
                  type={type}
                  onChange={onChange}
                  placeholder={value}
                  readOnly={readOnly}
                />
                <Form.Control.Feedback type="invalid">
                    {form.errors[field.name]}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    );
  };
  
  export default FormTextAreaTextField;