import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useUser } from "../UserProvider";
import ajax from "../Services/fetchService";
import { Formik } from "formik";
import * as yup from "yup";
import FormReadOnlyTextField from "../FormUtil/form-field-readonly";
import jwt_decode from "jwt-decode";
import FormTextAreaTextField from "../FormUtil/form-field-textarea";

const InstructorStudentFileReportModal = (props) => {
  const { emitClose, student, emitSaveRecord, staffName } = props;
  const user = useUser();
  const [userData, setUserData] = useState({});
  const decodedJwt = jwt_decode(user.jwt);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: student.firstName,
    lastName: student.lastName,
    student: student,
    notes: "",
  });

  const schema = yup.object().shape({
    notes: yup.string().required("Notes cannot be left blank").nonNullable()
  });

  useEffect(() => {
    setIsLoading(true);
  }, [])

  const handleSaveRecord = (values) => { 
    const recordToSave = {
        ...values,
        "student": student
    }

    emitSaveRecord(decodedJwt.sub, recordToSave);
  };

  return (
    <>
      <Modal show={true} onHide={emitClose}>
        {isLoading ? (
          <>
            <Modal.Header closeButton>
              {/* TODO: student name is temporary here until existing data can be reentered as first and last name
              change to concantenated first and last name after existing data has been reformatted to first and last name */}
              <Modal.Title>Student File Report for {student.firstName != null && student.lastName != null ? student.firstName + " " + student.lastName : student.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={handleSaveRecord}
                initialValues={{
                  firstName: initialValues.firstName,
                  lastName: initialValues.lastName,
                  staffName: initialValues.staffName,
                  notes: initialValues.notes,
                }}
              >
                {({ handleSubmit, handleChange, isValid, isSubmitting }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Col>
                        <FormReadOnlyTextField
                            as={Col}
                            sm="4"
                            controlId="firstName"
                            label="First Name"
                            type="text"
                            name="firstName"
                            value={student.firstName}
                            onChange={handleChange}
                        />

                        <FormReadOnlyTextField
                            as={Col}
                            sm="4"
                            controlId="lastName"
                            label="Last Name"
                            type="text"
                            name="lastName"
                            value={student.lastName}
                            onChange={handleChange}
                        />

                        <FormReadOnlyTextField
                            as={Col}
                            sm="4"
                            controlId="staffName"
                            label="Staff Generating the Report"
                            type="text"
                            name="staffName"
                            value={staffName != "" ? staffName : ""}
                            onChange={handleChange}
                        />

                        <FormTextAreaTextField
                            as={Col}
                            sm="4"
                            controlId="notes"
                            label="Notes"
                            type="text"
                            name="notes"
                            rows={5}
                            onChange={handleChange}
                            value={"Please enter any anecdotal notes pertaining to this student"}
                        />
                          
                    </Col>
                    <Row>
                      <Col className="mt-5">
                        <Button
                          disabled={!isValid || isSubmitting}
                          variant="success && text-white"
                          as="input"
                          size="md"
                          type="submit"
                          value="Submit"
                        />

                        <Button
                          className="mx-2"
                          variant="secondary"
                          onClick={emitClose}
                          value="Close"
                          as="input"
                          size="md"
                          type="button"
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </>
        ) : (
          <div className="p-5">Loading...</div>
        )}
      </Modal>
    </>
  );
};

export default InstructorStudentFileReportModal;
